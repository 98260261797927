import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CalculatorApp';
  heightOptions = [
    { name: '4ft. 7in.', value: this.feetToCm(4, 7)},
    { name: '4ft. 8in.', value: this.feetToCm(4, 8)},
    { name: '4ft. 9in.', value: this.feetToCm(4, 9)},
    { name: '4ft. 10in.', value: this.feetToCm(4, 10)},
    { name: '4ft. 11in.', value: this.feetToCm(4, 11)},
    { name: '5ft. 0in.', value: this.feetToCm(5, 0)},
    { name: '5ft. 1in.', value: this.feetToCm(5, 1)},
    { name: '5ft. 2in.', value: this.feetToCm(5, 2)},
    { name: '5ft. 3in.', value: this.feetToCm(5, 3)},
    { name: '5ft. 4in.', value: this.feetToCm(5, 4)},
    { name: '5ft. 5in.', value: this.feetToCm(5, 5)},
    { name: '5ft. 6in.', value: this.feetToCm(5, 6)},
    { name: '5ft. 7in.', value: this.feetToCm(5, 7)},
    { name: '5ft. 8in.', value: this.feetToCm(5, 8)},
    { name: '5ft. 9in.', value: this.feetToCm(5, 9)},
    { name: '5ft. 10in.', value: this.feetToCm(5, 10)},
    { name: '5ft. 11in.', value: this.feetToCm(5, 11)},
    { name: '6ft. 0in.', value: this.feetToCm(6, 0)},
    { name: '6ft. 1in.', value: this.feetToCm(6, 1)},
    { name: '6ft. 2in.', value: this.feetToCm(6, 2)},
    { name: '6ft. 3in.', value: this.feetToCm(6, 3)},
    { name: '6ft. 4in.', value: this.feetToCm(6, 4)},
    { name: '6ft. 5in.', value: this.feetToCm(6, 5)},
    { name: '6ft. 6in.', value: this.feetToCm(6, 6)},
    { name: '6ft. 7in.', value: this.feetToCm(6, 7)},
    { name: '6ft. 8in.', value: this.feetToCm(6, 8)},
    { name: '6ft. 9in.', value: this.feetToCm(6, 9)},
    { name: '6ft. 10in.', value: this.feetToCm(6, 10)},
    { name: '6ft. 11in.', value: this.feetToCm(6, 11)},
    { name: '7ft. 0in.', value: this.feetToCm(7, 0)},
  ];
  activityLevelOptions = [
    { name: 'Sedentary (0 workouts - office job)', value: 'Sedentary (0 workouts - office job)', formula: 1.20},
    { name: 'Light Exercise (workout 1-2 times/week)', value: 'Light Exercise (workout 1-2 times/week)', formula: 1.375},
    { name: 'Moderate Exercise (3-5 times/week)', value: 'Moderate Exercise (3-5 times/week)', formula: 1.55},
    { name: 'Heavy Exercise (6-7 times/week)', value: 'Heavy Exercise (6-7 times/week)', formula: 1.725},
    { name: 'Athlete (2x/day)', value: 'Athlete (2x/day)', formula: 1.9 }
  ];
  macroOptions = [
    { name: 'Lean Bulk (110% Surplus) ', value: 'Lean Bulk (110% Surplus)'},
    { name: 'Fat Loss (Deficit)', value: 'Fat Loss (Deficit)'},
    { name: 'Regular Bulk (120% Surplus)', value: 'Regular Bulk (120% Surplus)'},
    { name: 'Maintenance', value: 'Maintenance' }
  ]
  dataSource = [
    { name: 'Basal Metabolic Rate', formula: 1 },
    { name: 'Sedentary', formula: 1.20 },
    { name: 'Light Exercise', formula: 1.375 },
    { name: 'Moderate Exercise', formula: 1.55 },
    { name: 'Heavy Exercise', formula: 1.725 },
    { name: 'Athlete', formula: 1.9 },
  ];
  displayedColumns: string[] = ['position', 'name'];
  calculatorForm: FormGroup;
  bmr: number;
  calculated: boolean = false;
  chipData: { age: any[]; bodyWeight: any[]; };
  tdee: number;
  selectedHeight: any;
  macroNumber: number;
  lbm: number;
  proteinGrams: number;
  carbGrams: number;
  fatGrams: number;

  ngOnInit(): void {
      this.calculatorForm = new FormGroup({
        age: new FormControl('', Validators.required),
        height: new FormControl('', Validators.required),
        bodyFat: new FormControl('', [Validators.required, Validators.max(100)]),
        bodyWeight: new FormControl('', Validators.required),
        gender: new FormControl('', Validators.required), 
        activityLevel: new FormControl('', Validators.required),
        macro: new FormControl('', Validators.required),
        macroType: new FormControl('', Validators.required)
      })
  }
  submitForm(el: HTMLElement){
    if(this.calculatorForm.valid){
      const TBW = this.calculatorForm.value.bodyWeight * 0.45359237;
      const BFP = this.calculatorForm.value.bodyFat;
      const AGE = this.calculatorForm.value.age;
      const FM = TBW * (BFP/100);
      const LBM = TBW - FM;
      this.lbm = LBM
      const GENDER = this.calculatorForm.value.gender === 'male' ? 1 : 0;
      this.bmr = (13.587 * LBM) + (9.613 * FM) + (198 * GENDER) - (3.351 * AGE) + 674;
      this.calculated = true;
      const height = this.selectedHeight
      this.chipData = {
        ...this.calculatorForm.value,
        height: height
      }
      const selectedActivityVal = this.activityLevelOptions
      .filter(val=> val.name === this.calculatorForm.value.activityLevel)[0].formula
      this.tdee = this.bmr * selectedActivityVal;
      this.macroNumber = this.setMacroCalorie(BFP, GENDER, TBW);
      setTimeout(() => {
        el.scrollIntoView({behavior: 'smooth'});
      }, 300);
    }
  }
  resetForm(){
    this.calculatorForm.reset()
    this.calculated = false;
  }

  getSelHeightInFt(event){
    this.selectedHeight = event.source.selected.viewValue
  }

  feetToCm(ft, inch){
    return ((ft * 30.48) + (inch * 2.54)).toFixed(2)
  }

  setMacroCalorie(bfp, gender, TBW){
    const macro = this.calculatorForm.value.macro;
    this.proteinGrams = 2.6 * this.lbm;
    if(macro === 'Lean Bulk (110% Surplus)'){
      const LBC = this.tdee * 1.1;
      this.calculateCarbFat(LBC)
      return LBC
    } else if(macro === 'Regular Bulk (120% Surplus)'){
      const LBC = this.tdee * 1.2;
      this.calculateCarbFat(LBC)
      return LBC
    } else if(macro === 'Maintenance'){
      this.carbGrams = 0
      this.fatGrams = 0
      return this.tdee
    } else if(macro === 'Fat Loss (Deficit)'){
      const CD = this.calorieDeficit(bfp, gender);
      const WLG = TBW * 0.006
      const FLC = this.tdee - (WLG * CD)
      this.calculateCarbFat(FLC)
      return FLC
    }
  }
  
  calorieDeficit(bfp, gender){
    if((bfp>=27 && gender) || (bfp>=38 && !gender)){
      return 1024
    } else if((bfp>=20 && gender) || (bfp>=33 && !gender)){
      return 1024
    } else if((bfp>=10 && gender) || (bfp>=21 && !gender)){
      return 930
    } else if((bfp<10 && gender) || (bfp<21 && !gender)){
      return 834
    } 
  }

  calculateCarbFat(calorie){
    const macroType = this.calculatorForm.value.macroType;
    if(macroType === 'carb'){
      this.carbGrams = ((calorie - (this.proteinGrams*4)) * 0.6) / 4
      this.fatGrams = ((calorie - (this.proteinGrams*4)) * 0.4) / 9
    }
    else {
      this.carbGrams = ((calorie - (this.proteinGrams*4)) * 0.4) / 4
      this.fatGrams = ((calorie - (this.proteinGrams*4)) * 0.6) / 9
    }
  }

}
